/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.25);
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 2px;
  background: #e8e3d2;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 1);
}

html {
  scroll-behavior: smooth;
}
